import React from "react"
import { graphql, PageProps } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"
import Wave from "../components/svg/wave";
import Card from "../components/card";
import { signup, youtubeConditions } from '../assets/data/links.json';
import monetisationYoutube from '../config/contents/monetisation-youtube';

import cashSettingsIcon from '../assets/icons/cash-settings.svg';
import protectionIcon from '../assets/icons/protection.svg';
import '../assets/css/monetizaation-youtube.css';

const MonetisationYoutubePage: React.FC<PageProps<any>> = ({ data }) => {
  const { pageImage, site } = data;
  const { title, path, description } = site?.siteMetadata?.pages?.monetisationYoutube;
  const bgHeroStyle = {
    backgroundImage: `url(${pageImage?.childImageSharp.fluid.src})`,
  };

  return (
    <Layout className='monetization-youtube__page bg-white'>
      <SEO title={title} description={description} path={path} image={pageImage?.childImageSharp.fluid.src} />

      <section className="position-relative hero__image-bg bg_size_cover" style={bgHeroStyle}>

        <div className="position-absolute h-100 w-100 bg-gradient"></div>

        <div className="container">
          <div className="container__row row pt-5">
            <div className="hero__content z-index_2 text-white pt-3 pt-md-5">
              <h1 className="title mt-0 mt-md-4 mb-3 mb-md-5 pt-3">
                <strong className="d-bold">
                  {monetisationYoutube.section1.mainTitle.part1}{` `}
                </strong>
                {monetisationYoutube.section1.mainTitle.part2}
              </h1>
              <p className='font-weight-light pr-0 pr-md-4'>{monetisationYoutube.section1.description}</p>
            </div>
          </div>
        </div>

        <Wave
          stopColorTo='#A32378'
          stopColorFrom='#F61E40'
          className='wave-icon z-index_1 hero-wave position-absolute position_bottom mb-n5 mr-n5 size_big filter_blur_9 opacity_2' />
        <Wave
          stopColorTo='#A32378'
          stopColorFrom='#F61E40'
          className='wave-icon z-index_1 hero-wave position-absolute position_bottom' />

      </section>

      <section className="position-relative youtube-program__block bg-darkengrey overflow-hidden">

        <div className="container">
          <div className="container__row row pt-3 pt-md-5">

            <Wave
              stopColorTo='#A32378'
              stopColorFrom='#F61E40'
              className='wave-icon hero-wave position-absolute mb-n5 mr-n5 size_big filter_blur_9 opacity_2' />
            <Wave
              stopColorTo='#A32378'
              stopColorFrom='#F61E40'
              className='wave-icon hero-wave position-absolute' />

            <h2 className="title m-0 d-flex flex-column pt-0 pt-md-4 mb-3 mb-md-5">
              <span>
                {monetisationYoutube.section2.mainTitle.part1}{` `}
              </span>
              <strong className="d-bold">
                {monetisationYoutube.section2.mainTitle.part2}
              </strong>
            </h2>

            <div className="container__row row d-flex flex-column flex-md-row cards__block pt-0 pt-4">

              <Card
                Image={
                  <img src={cashSettingsIcon} alt={monetisationYoutube.section2.cards.card1.title} />
                }
                imageContainerClassName='text-center'
                titleClassName='fsz-20'
                title={monetisationYoutube.section2.cards.card1.title}
                content={monetisationYoutube.section2.cards.card1.description}
                link={signup}
                externalLink
                linkLabel={monetisationYoutube.section2.cards.card1.linkLabel}
                linkClassName='w-100 mt-4'
              />

              <Card
                Image={
                  <img src={protectionIcon} alt={monetisationYoutube.section2.cards.card2.title} />
                }
                imageContainerClassName='text-center'
                titleClassName='fsz-20'
                title={monetisationYoutube.section2.cards.card2.title}
                content={monetisationYoutube.section2.cards.card2.description}
                link={youtubeConditions}
                linkLabel={monetisationYoutube.section2.cards.card2.linkLabel}
                linkClassName='w-100 bg-darkengrey bg-none text-red mt-4'
              />

            </div>

          </div>
        </div>

      </section>


    </Layout>
  );
}

export const query = graphql`
  query YoutubeQuery {
    site {
      siteMetadata {
        lang
        pages {
          monetisationYoutube {
            title
            path
            description
          }
        }
      }
    }
    pageImage: file(relativePath: { eq: "monetization-youtube/hero-bg.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 1440) {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`;

export default MonetisationYoutubePage;
