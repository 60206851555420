const monetisationYoutube = {
  section1: {
    mainTitle: {
      part1: `Gagnez de l'argent`,
      part2: `à chaque fois que vos titres sont joués sur YouTube`,
    },
    description: `Vous pouvez désormais tirer le meilleur parti de tout votre contenu audiovisuel, pour vos nouveaux clips vidéos et ce, même si vous ne possédez pas de chaine Youtube. Nous activerons le programme de paiement YouTube en votre nom avec des conditions très spéciales. Grâce à notre programme vous serez gagnants, vos vidéos seront transformées en sources de revenus supplémentaires.`,
  },
  section2: {
    mainTitle: {
      part1: `Le programme YouTube`,
      part2: `Content ID de Dropzik`
    },
    description: `Veuillez consulter nos conditions générales complètes pour plus de détails.`,
    linkLabel1: `Créez un compte gratuitement`,
    linkLabel2: `Terms et conditions`,
    cards: {
      card1: {
        title: `Des revenus accrus pour vos vues:`,
        description: `Grâce à nos accords exclusifs avec les principaux partenaires technologiques mondiaux.`,
        linkLabel: `créer un compte gratuitement`,
      },
      card2: {
        title: `Protection accrue de vos droits:`,
        description: `Si vous trouvez votre contenu téléchargé sur YouTube par des tiers sans votre permission ou consentement, nous vous offrons notre assistance juridique complète pour vous assurer qu’il est supprimé en moins de 24 heures.`,
        linkLabel: `Termes et conditions`,
      },
    }
  },
};

export default monetisationYoutube;